import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src807903991/src/joshuachongrocks/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>&nbsp;</p>
    <h3>About this site</h3>
This site was made with <Link to='https://www.gatsbyjs.org/' mdxType="Link">Gatsby</Link> and using the theme <Link to='https://github.com/Chronoblog/gatsby-theme-chronoblog' mdxType="Link">Gatsby Chronoblog</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      